import {
	MenuItem,
	Select,
	InputLabel,
	FormControl,
	Autocomplete,
	TextField,
	Chip,
} from "@material-ui/core";
import { useQuery } from "react-query";
import useApi from "src/api/api";
import { useLocale } from "src/provider/LocaleProvider";
import LoadingView from "./LoadingView";

const _ = require("lodash");

export function SubjectSelector({
	selected,
	multiple = false,
	setSelected = () => { },
	...rest
}) {
	const api = useApi();
	const { t, getLanguage } = useLocale();

	const { data: subjects, isLoading } = useQuery("subjects", async () => {
		return api.getSubjects().then(({ data }) => {
			return data?.subjects;
		});
	});

	const selectedValue = _.filter(
		subjects,
		(item) => selected && _.includes(selected, item._id),
	);

	const allSubject = {
		name: {
			en: t("uncategorized"),
			zh: t("uncategorized"),
		},
		_id: "",
		attribute: null,
		value: null,
	};

	if (isLoading & !_.isArray(subjects)) return <LoadingView />;
	if (multiple)
		return (
			<Autocomplete
				multiple
				openOnFocus
				autoHighlight
				size="medium"
				disableClearable
				id="tags-standard-subject"
				options={[allSubject, ...subjects]}
				getOptionLabel={(subject) =>
					_.get(subject, `name.${getLanguage()}`, "")
				}
				onChange={(_e, subjects, _c, selectedSubject) => {
					if (selectedSubject.option.value === null) {
						setSelected([]);
					} else {
						const subjectIdList = _.map(
							subjects,
							(subject) => subject._id,
						).filter((id) => id);

						setSelected(subjectIdList);
					}
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
						label={t("subject")}
						placeholder="Optional"
					/>
				)}
				value={selectedValue.length > 0 ? selectedValue : [allSubject]}
				renderTags={(tagValue, getTagProps) => {
					return tagValue.map((option, index) => {
						return (
							<Chip
								key={_.get(option, `name.${getLanguage()}`, "")}
								variant="outlined"
								label={_.get(option, `name.${getLanguage()}`, "")}
								{...getTagProps({ index })}
							/>
						);
					});
				}}
				{...rest}
			/>
		);

	return (
		<FormControl {...rest}>
			<InputLabel sx={{ m: "auto" }}>{t("subject")}</InputLabel>
			<Select
				className="mySelect"
				label={t("subject")}
				variant="outlined"
				size="small"
				displayEmpty
				onChange={(event) => setSelected(event.target.value)}
				value={selected}
				defaultValue={""}
				sx={{ height: "40px", minWidth: "150px", width: "100%", mb: 2 }}
				fullWidth={true}
			>
				<MenuItem value={"ALL"}>{t("all_subject")}</MenuItem>
				<MenuItem value={"NONE"}>{t("uncategorized")}</MenuItem>
				{_.map(subjects, (subject) => (
					<MenuItem key={subject?.name?.en} value={subject?._id}>
						{`${_.get(subject, `name.${getLanguage()}`)}`}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
