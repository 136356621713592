import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Tab,
  Tabs,
  TextField
} from '@material-ui/core';
import { useFormik } from 'formik';
import Fuse from 'fuse.js';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import useApi from 'src/api/api';
import ClassSelector from 'src/components/ClassSelector';
import EditDialog from 'src/components/EditDialog';
import NoRecordItem from 'src/components/NoRecordItem';
import SearchInput from "src/components/SearchInput";
import { SubjectSelector } from 'src/components/SubjectSelector';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useLocale } from 'src/provider/LocaleProvider';
import formikProps from 'src/utils/formikProps';
import { GameListCard } from './GameListCard';

// const prettyBytes = require('pretty-bytes');
const yup = require('yup');

export default function GameList({ snack }) {
  const nav = useNavigate();
  const { t } = useLocale();
  const api = useApi();
  const [dialogMode, setDialogMode] = useState();
  const [tab, setTab] = useState('single');
  const [selectedSubject, setSelectedSubject] = useState('ALL');
  const [selectedClass, setSelectedClass] = useState();

  const [gameList, setGameList] = useState([]);
  const [gameListBySubject, setGameListBySubject] = useState([]);
  const [search, setSearh] = useState('');

  const deboundedSearch = _.debounce((value) => {
    setSearh(value);
  }, 500);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    api.getGameList().then((res) => {
      setGameList(res.data);
    });
  }, []);

  const fuse = useMemo(() => {
    return new Fuse(gameList, {
      isCaseSensitive: false,
      useExtendedSearch: true,
      keys: ['subjects._id', 'filters._id', "name", "description"],
    })
  }, [gameList])

  useEffect(() => {
    const fuesParams = [];

    // Apply name filter using Fuse.js
    (selectedClass?._id) &&
      fuesParams.push({ "filters._id": `'${selectedClass._id}` });

    (search) &&
      fuesParams.push({ $or: [{ name: search }, { description: search }] });

    (selectedSubject !== "ALL" && selectedSubject !== "NONE") &&
      fuesParams.push({ "subjects._id": `'${selectedSubject}` });

    let fuseResult = fuesParams.length ?
      fuse.search({ $and: fuesParams }).map((item) => item.item) :
      gameList;

    if (selectedSubject === "NONE") {
      fuseResult = fuseResult.filter((g) => _.isEmpty(g.subjects))
    }

    if (selectedClass?.value === "NONE") {
      fuseResult = fuseResult.filter((g) => _.isEmpty(g.filters))
    }

    setGameListBySubject(fuseResult)

  }, [selectedSubject, gameList, selectedClass, search, fuse]);



  useEffect(() => {
    if (dialogMode) formik.resetForm();
  }, [dialogMode]);

  const formik = useFormik({
    initialValues: {
      name: '',
      gameType: 'mc',
      pointCorrect: null,
      pointIncorrect: null,
      pointExchangeRate: null,
      isActive: true,
      filters: [],
      subjects: []
    },
    enableReinitialize: true,
    validationSchema: yup
      .object({
        name: yup.string().required(),
        gameType: yup
          .string()
          .oneOf(['mc', 'interactive'])
          .required()
          .nullable(),
        isActive: yup.bool().required(),
        filters: yup.array()
      })
      .when('this', {
        is: (v) => formik.values.gameType !== 'interactive',
        // biome-ignore lint/suspicious/noThenProperty: <explanation>
        then: yup.object().shape({
          pointCorrect: yup.number().min(0).required().nullable(),
          pointIncorrect: yup.number().min(0).required().nullable(),
          pointExchangeRate: yup.number().min(0).required().nullable()
        })
      }),
    onSubmit: (values, helper) => {
      api
        .createGame(values)
        .then(async (res) => {
          nav(`/admin/games/${res.data._id}`);
          // await api.getGameList().then(res => {
          //     setGameList(res.data);
          //     setDialogMode(null);
          // });
        })
        .catch((e) => {
          snack.error();
        })
        .finally(() => {
          helper.setSubmitting(false);
        });
    }
  });
  const hasPointConfig = formik.values.gameType === 'mc';

  const gameListByType = useMemo(() => {
    return _.groupBy(gameListBySubject, (g) => g.gameType);
  }, [gameListBySubject])
  const [filteredList, setFilteredList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (tab === 'multi') {
      setFilteredList(_.get(gameListByType, 'interactive', []));
    } if (tab === 'single') {
      setFilteredList(_.get(gameListByType, 'mc', []));
    }
  }, [tab, gameListByType]);

  const setIsGameActiveInList = (id) => {
    setFilteredList(_.map(filteredList, (game) =>
      (game._id === id) ?
        { ...game, isActive: !game.isActive } :
        game
    ))
  }

  const updateGameStatusBulk = () => {
    setIsSaving(true)
    const data = _.map(filteredList, (game) => {
      return { _id: game._id, isActive: game.isActive }
    })
    api.updateGameStatusBulk(data).then(_ => {
      snack.open(t('upload_success'));
    })
      .catch(_ => {
        snack.error();
      }).finally(() => {
        setIsSaving(false)
      })
  }

  return (
    <LayoutContainer title={t('game.game')}>
      <Box display="flex" justifyContent={"space-between"} mb={2}>
        <Box display={"flex"}>
          <SearchInput setSearch={deboundedSearch} />
          <ClassSelector
            selected={selectedClass}
            setSelected={setSelectedClass}
            hasUncategorized={true}
            is
            sx={{ mx: 1 }}
          />
          <SubjectSelector
            selected={selectedSubject}
            setSelected={setSelectedSubject}
            sx={{ mb: 2 }} />
        </Box>

        <Box display={"flex"} sx={{ gap: 1 }}>
          <Button onClick={() => setDialogMode('add')}>{t('add_game')}</Button>
          <Button onClick={() => updateGameStatusBulk()} disabled={isSaving}>{t('save')}</Button>
        </Box>
      </Box>
      <Card sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={(_e, v) => setTab(v)}>
          <Tab label={t('single_player_mode')} value={'single'} />
          <Tab label={t('multi_player_mode')} value={'multi'} />
        </Tabs>
      </Card>
      <Grid container spacing={4}>
        {_.size(filteredList) > 0 ? (
          _.map(filteredList, (game) =>
            <GameListCard game={game} key={game._id} setIsGameActiveInList={setIsGameActiveInList} />
          )
        ) : (
          <NoRecordItem grid />
        )}
      </Grid>
      <EditDialog
        title={t('add_game')}
        handleSave={formik.handleSubmit}
        open={dialogMode === 'add'}
        handleClose={() => setDialogMode(null)}
        sx={{ overflowY: 'hidden' }}
      >
        <TextField
          fullWidth
          label={t('name')}
          {...formikProps(formik, 'name')}
          sx={{ mb: 2 }}
        />
        <SubjectSelector
          fullWidth
          sx={{ mb: 2 }}
          multiple
          selected={_.get(formik.values, 'subjects')}
          setSelected={(v) => formik.setFieldValue('subjects', v)}
        />

        <ClassSelector
          fullWidth
          multiple
          size="medium"
          // {...formikProps(formik, 'filters')}
          selected={_.get(formik.values, 'filters')}
          setSelected={(v) => formik.setFieldValue('filters', v)}
          sx={{ mb: 2 }}
        />
        <TextField
          select
          {...formikProps(formik, 'gameType')}
          label={t('type')}
          sx={{ mb: 2, width: { xs: '100%', sm: '50%' }, pr: { xs: 0, sm: 2 } }}
        >
          <MenuItem value={'mc'}>
            <span>{t('game_mc')}</span>
            <span style={{ color: 'gray', paddingLeft: '8px' }}>
              {`( ${t('single_player_mode')} )`}
            </span>
          </MenuItem>
          <MenuItem value={'interactive'}>
            <span>{t('game_interactive')}</span>
            <span style={{ color: 'gray', paddingLeft: '8px' }}>
              {`( ${t('multi_player_mode')} )`}
            </span>
          </MenuItem>
        </TextField>
        {hasPointConfig && (
          <>
            <TextField
              label={t('point_correct')}
              {...formikProps(formik, 'pointCorrect')}
              sx={{ mb: 2, width: { xs: '100%', sm: '50%' } }}
            />
            <TextField
              label={t('point_incorrect')}
              {...formikProps(formik, 'pointIncorrect')}
              sx={{
                mb: 2,
                width: { xs: '100%', sm: '50%' },
                pr: { xs: 0, sm: 2 }
              }}
            />
            <TextField
              label={t('point_exchange_rate')}
              {...formikProps(formik, 'pointExchangeRate')}
              sx={{ mb: 2, width: { xs: '100%', sm: '50%' } }}
            />
          </>
        )}
      </EditDialog>
    </LayoutContainer>
  );
}