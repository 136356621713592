import {
  Autocomplete,
  Chip,
  TextField
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import useApi from 'src/api/api';
import { useLocale } from 'src/provider/LocaleProvider';

const _ = require('lodash');

const ClassSelector = ({
  default_id,
  selected,
  setSelected = new Function(),
  filters,
  autocomplete = true,
  all = true,
  multiple = false,
  hasUncategorized = false,
  ...rest
}) => {
  const { t, getLanguage } = useLocale();
  const [filterList, setFilterList] = useState([]);
  const api = useApi();
  const allOption = {
    name: {
      en: t('all_grade_and_class'),
      zh: t('all_grade_and_class')
    },
    attribute: null,
    value: null
  };
  const noneOption = {
    name: {
      en: t('uncategorized'),
      zh: t('uncategorized')
    },
    attribute: null,
    value: "NONE"
  };

  let presetOptions = [];
  if (all) {
    presetOptions.push(allOption);
  }

  if (hasUncategorized) {
    presetOptions.push(noneOption);
  }

  // const presetOptions = hasUncategorized ? [allOption, noneOption] : [allOption]

  useEffect(() => {
    if (filters) {
      setFilterList(filters);
    } else {
      api.getOrganizationFilters().then((res) => {
        const result = res.data;

        setFilterList(result);
      });
    }
    if ((!selected || selected == {}) && all) setSelected(allOption);
  }, []);

  let selectedValue = _.filter(
    filterList,
    (item) => selected && _.includes(selected, item._id)
  );

  return multiple ? (
    <Autocomplete
      multiple
      size="medium"
      openOnFocus
      autoHighlight
      disableClearable
      value={selectedValue.length > 0 ? selectedValue : [allOption]}
      isOptionEqualToValue={(option, value) => {
        return option.value === value.value;
      }}
      options={_.concat(presetOptions, filterList)}
      getOptionLabel={(op) => _.get(op, `name.${getLanguage()}`, '')}
      onChange={(e, v, c, d) => {
        if (d.option.value === null) {
          setSelected([]);
        } else setSelected(_.map(v, (i) => i._id));
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => {
          return (
            <Chip
              // size='small'
              variant="outlined"
              label={_.get(option, `name.${getLanguage()}`, '')}
              {...getTagProps({ index })}
            // disabled={filterList.indexOf(option) === -1}
            />
          );
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('grade_or_class')}
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
        />
      )}
      style={{ minWidth: '150px' }}
      {...rest}
    />
  ) : (
    //single
    <Autocomplete
      size="small"
      openOnFocus
      autoHighlight
      disableClearable
      value={selected || allOption}
      options={_.concat(presetOptions, filterList)}
      getOptionLabel={(op) => _.get(op, `name.${getLanguage()}`, '')}
      isOptionEqualToValue={(option, value) => {
        return option.value === value.value;
      }}
      onChange={(e, v) => setSelected(v)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('grade_or_class')}
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
        />
      )}
      style={{ minWidth: '150px' }}
      {...rest}
    />
  );
};
export default ClassSelector;
