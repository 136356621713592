import { Box, Card, Divider, Grid, Typography } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import _ from 'lodash';
import { useState, useMemo } from 'react';
import useApi from 'src/api/api';
import vars from 'src/config/vars';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useLocale } from 'src/provider/LocaleProvider';
import { SubjectSelector } from 'src/components/SubjectSelector';
import { useQuery } from 'react-query';
import LoadingView from 'src/components/LoadingView';
import SubjectIcon from '@material-ui/icons/Subject';

export default function UserGameList() {
  const { t, translate } = useLocale();
  const api = useApi();
  const [selectedSubject, setSelectedSubject] = useState('ALL');

  const authToken = localStorage.getItem('token');

  const { data: gameList, isLoading } = useQuery('games', async () => {
    return api
      .getGameList()
      .then((res) => _.filter(res.data, (g) => g.gameType === 'mc'));
  });

  const gamesFilteredBySubject = useMemo(() => {
    if (selectedSubject === 'ALL') return gameList;

    const newGameList = _.filter(gameList, (game) => {
      if (selectedSubject === 'NONE') {
        return _.isEmpty(game?.subjects);
      }
      return _.includes(game.subjects, selectedSubject);
    });
    return newGameList;
  }, [selectedSubject, gameList]);

  return (
    <LayoutContainer title={t('game.game')}>
      <SubjectSelector
        selected={selectedSubject}
        setSelected={setSelectedSubject}
        sx={{ my: 1 }}
      />
      {isLoading ? (
        <LoadingView />
      ) : (
        <Grid container spacing={4}>
          {gamesFilteredBySubject.length === 0 ? (
            <Box my={12} mx={'auto'}>
              <Typography>{t('no_record')}</Typography>
            </Box>
          ) : (
            _.map(gamesFilteredBySubject, (game) => {
              return (
                <Grid
                  key={game._id}
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    '& .MuiGrid-item': {
                      height: '120px'
                    },
                    '& img': {
                      height: '100%',
                      width: '100%'
                    },
                    '& .MuiTypography-root': {
                      overflow: 'hidden',
                      lineHeight: '25px',
                      maxHeight: '50px'
                    }
                  }}
                >
                  <a
                    href={`${vars.extenalURL.gameMC}/login/${game._id}?token=${authToken}`}
                    target="_blank"
                    style={{ width: '100%' }}
                    rel="noreferrer"
                  >
                    <Card>
                      <Grid container>
                        <Grid
                          item
                          sx={{ p: 2, height: '120px', width: '120px' }}
                        >
                          <img
                            alt="logo"
                            src={game.logo || '/images/game.png'}
                          />
                        </Grid>
                        <Grid item xs lg sx={{ p: 2 }}>
                          <Typography variant="h4">{game.name}</Typography>
                          <Typography color="textSecondary" variant="h5">
                            {game.description}
                          </Typography>
                        </Grid>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ display: { xs: 'none', lg: 'block' } }}
                        />
                        <Divider
                          flexItem
                          sx={{
                            display: { xs: 'block', lg: 'none' },
                            width: '100%'
                          }}
                        />
                        <Grid item xs={12} lg={4} sx={{ p: 1 }}>
                          <Box display="flex">
                            <DescriptionOutlinedIcon />
                            <Box pr={1} />
                            <Typography
                              noWrap
                              color="textSecondary"
                              variant="h5"
                            >
                              {t(`game_${game.gameType}`)}
                            </Typography>
                          </Box>
                          <Box display="flex">
                            <PeopleAltOutlinedIcon />
                            <Box pr={1} />
                            <Typography color="textSecondary" variant="h5">
                              {_.size(game.filters) > 0
                                ? _.map(game.filters, (f) => translate(f.name))
                                  .sort()
                                  .join(', ')
                                : `${t('uncategorized')} (${t('all_grade_and_class')})`}
                            </Typography>
                          </Box>
                          <Box display="flex">
                            <SubjectIcon />
                            <Box pr={1} />
                            <Typography
                              noWrap
                              color="textSecondary"
                              variant="h5"
                            >
                              {_.size(game?.subjects) > 0
                                ? _.map(game?.subjects, (f) =>
                                  translate(f.name)
                                )
                                  .sort()
                                  .join(', ')
                                : `${t('uncategorized')} (${t('all_subject')})`}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  </a>
                </Grid>
              );
            })
          )}
        </Grid>
      )}
    </LayoutContainer>
  );
}
