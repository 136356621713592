import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import SubjectIcon from '@material-ui/icons/Subject';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import useApi from 'src/api/api';
import ClassSelector from 'src/components/ClassSelector';
import EditDialog from 'src/components/EditDialog';
import ImageUploader from 'src/components/Formik/ImageUploader';
import { SubjectSelector } from 'src/components/SubjectSelector';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useLocale } from 'src/provider/LocaleProvider';
import formikProps from 'src/utils/formikProps';
import QuestionCard from '../../components/Game/QuestionCard';
// const prettyBytes = require('pretty-bytes');
const yup = require('yup');

const DataBox = ({ title, value, Icon, className, ...rest }) => {
  return (
    <Box
      sx={{
        '& .avatar': {
          backgroundColor: '#e3f2fd',
          color: '#1e88e5',
          height: 56,
          width: 56
        }
      }}
      {...rest}
    >
      <CardContent sx={{ p: 2 }}>
        <Grid container justify="space-between" spacing={0}>
          <Grid item xs>
            <Typography color="textSecondary" gutterBottom variant="h5">
              {title}
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {value}
            </Typography>
          </Grid>
          <Grid item style={{ alignSelf: 'center' }}>
            <Avatar className={'avatar'}>{Icon}</Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
};

export default function GameDetail({ snack }) {
  const nav = useNavigate();
  const api = useApi();
  const { t, translate } = useLocale();
  const [dialogMode, setDialogMode] = useState();
  const { gameId } = useParams();
  const [game, setGame] = useState({});
  const [totalSession, setTotalSession] = useState(0);
  const [totalUser, setTotalUser] = useState(0);

  const hasImage = game.gameType === 'mc';
  const hasMoreInsight = game.gameType === 'mc';

  const confirmDelete = () => setDialogMode('delete');

  const doDeleteGame = () => {
    api.deleteGame(game._id).then(() => {
      snack.open(t('delete_success'));
      nav('/admin/games');
    }).catch(e => {
      snack.error();
      setDialogMode(null);
    })
  }

  useEffect(() => {
    setTotalSession(_.size(game.sessions));
    var unique = _.map(game.sessions, (item) => item.user).filter(
      (v, i, a) => a.indexOf(v) === i
    );
    setTotalUser(_.size(unique));
  }, [game.sessions]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    return api.getGameData(gameId, { sessions: true }).then((res) => {
      setGame(res.data);
    });
  };

  useEffect(() => {
    if (dialogMode) formik.resetForm();
  }, [dialogMode]);

  const formik = useFormik({
    initialValues: {
      ...game,
      filters: _.map(game.filters, (f) => f?._id),
      subjects: _.map(game.subjects, (f) => f?._id)
    } || {
      name: '',
      image: '',
      gameType: 'mc',
      pointCorrect: null,
      pointIncorrect: null,
      pointExchangeRate: null,
      isActive: true,
      filters: [],
      subjects: []
    },
    enableReinitialize: true,
    validationSchema: yup
      .object({
        name: yup.string().required(),
        gameType: yup
          .string()
          .oneOf(['mc', 'interactive'])
          .required()
          .nullable(),
        isActive: yup.bool().required(),
        filters: yup.array()
      })
      .when('this', {
        is: function (v) {
          return formik.values.gameType !== 'interactive';
        },
        then: yup.object().shape({
          pointCorrect: yup.number().min(0).required().nullable(),
          pointIncorrect: yup.number().min(0).required().nullable(),
          pointExchangeRate: yup.number().min(0).required().nullable()
        })
      }),
    onSubmit: (values, helper) => {
      values.sessions = undefined;
      api
        .updateGame(gameId, values)
        .then(async (res) => {
          getData();
          setDialogMode(null);
          snack.open(t('upload_success'));
        })
        .catch((e) => {
          snack.error();
        })
        .finally(() => {
          helper.setSubmitting(false);
        });
    }
  });

  const hasPointConfig = formik.values.gameType === 'mc';
  const hasSessionRecord = formik.values.gameType === 'mc';

  if (!game?._id) return null;
  return (
    <LayoutContainer title={t('edit_game')} backSrc={`/admin/games`}>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid
          item
          xs={12}
          sm={hasSessionRecord ? 8 : 12}
          sx={{
            height: '100%',
            '& .MuiGrid-item': {
              // height: '120px',
            },
            '& img': {
              height: '100%',
              width: '100%'
            },
            '& .MuiTypography-root': {
              overflow: 'hidden',
              lineHeight: '32px',
              maxHeight: '64px'
            }
          }}
        >
          <Card>
            <Grid container>
              <Grid item sx={{ p: 2, height: '120px', width: '120px' }}>
                <img src={game.logo || '/images/game.png'} alt="logo" />
              </Grid>
              <Grid item xs lg sx={{ p: 2, height: '120px' }}>
                <Typography variant="h3">{game.name}</Typography>
                <Typography color="textSecondary" variant="h4">
                  {game.description}
                </Typography>
              </Grid>
              {/* <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', lg: 'block' } }} /> */}
              <Divider flexItem sx={{ width: '100%' }} />
              <Grid item xs={12} sx={{ p: 2 }}>
                <Box display="flex">
                  <DescriptionOutlinedIcon />
                  <Box pr={1} />
                  <Typography noWrap color="textSecondary" variant="h4">
                    {t('gametype') + ' : ' + t(`game_${game.gameType}`)}
                  </Typography>
                </Box>

                <Box display="flex">
                  <PeopleAltOutlinedIcon />
                  <Box pr={1} />
                  <Typography color="textSecondary" variant="h4">
                    {t('grade_or_class') +
                      ' : ' +
                      (_.size(game.filters) > 0
                        ? _.map(game.filters, (f) => translate(f.name))
                          .sort()
                          .join(', ')
                        : t('all_grade_and_class'))}
                  </Typography>
                </Box>
                <Box display="flex">
                  <SubjectIcon />
                  <Box pr={1} />
                  <Typography noWrap color="textSecondary" variant="h4">
                    {_.size(game?.subjects) > 0
                      ? _.map(game?.subjects, (f) => translate(f.name))
                        .sort()
                        .join(', ')
                      : t('all_subject')}
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => setDialogMode('edit')}
                  sx={{ mt: 4, mr: 1 }}
                >
                  {t('edit_game')}
                </Button>
                {/* <Button href={`http://localhost:3700/admin?gameId=${game._id}`} target='_blank' variant='outlined' color='inherit' sx={{ mt: 4 }}>
                                    {t('play_game')}
                                </Button> */}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {hasSessionRecord && (
          <Grid item xs={12} sm={4}>
            <Card sx={{ height: '100%' }}>
              <DataBox
                title={t('game.total_session')}
                value={totalSession}
                Icon={<AssignmentIcon />}
              />
              <Divider style={{ margin: '6px 0' }} />
              <DataBox
                title={t('game.total_user')}
                value={totalUser}
                Icon={<PeopleAltIcon />}
              />
              <Divider style={{ margin: '6px 0' }} />
              {hasMoreInsight && (
                <Box sx={{ textAlign: 'center' }}>
                  <Button
                    color="primary"
                    size="medium"
                    variant="text"
                    onClick={() => nav(`/admin/games/record/${gameId}`)}
                  >
                    {t('game.more_insight')} <Icons.ArrowRightIcon />
                  </Button>
                </Box>
              )}
            </Card>
          </Grid>
        )}
      </Grid>
      <Box display="flex" width="100%" textAlign="left" mb={2}>
        <Typography variant="h3">
          {' '}
          {_.size(game.questions)} {t('questions')}{' '}
        </Typography>
        <Box flexGrow={1} />
        <NavLink
          style={{ marginLeft: '20px' }}
          to={`/admin/games/edit/${gameId}`}
        >
          <Button variant="outlined" startIcon={<BorderColorOutlinedIcon />}>
            {t('edit_question')}
          </Button>
        </NavLink>
      </Box>
      {_.map(game?.questions, (question, index) => {
        return (
          <QuestionCard question={question} index={index} hasImage={hasImage} />
        );
      })}
      <EditDialog
        title={t('edit_game')}
        handleSave={formik.handleSubmit}
        open={dialogMode === 'edit'}
        handleClose={() => setDialogMode(null)}
        TitleComponent={() => (
          <IconButton onClick={() => confirmDelete()}  >
            <Icons.DeleteForeverIcon />
          </IconButton>
        )}

      >
        <TextField
          fullWidth
          label={t('name')}
          {...formikProps(formik, 'name')}
          sx={{ mb: 2 }}
        />
        <SubjectSelector
          fullWidth
          sx={{ mb: 2 }}
          multiple
          selected={_.get(formik.values, 'subjects')}
          setSelected={(v) => formik.setFieldValue('subjects', v)}
        />
        <ClassSelector
          fullWidth
          multiple
          size="medium"
          // {...formikProps(formik, 'filters')}
          selected={formik.values?.filters}
          setSelected={(v) => formik.setFieldValue('filters', v)}
          sx={{ mb: 2 }}
        />
        <TextField
          select
          {...formikProps(formik, 'gameType')}
          label={t('type')}
          sx={{ mb: 2, width: { xs: '100%', sm: '100%' } }}
        >
          <MenuItem value={'mc'}> {t('game_mc')} </MenuItem>
          <MenuItem value={'interactive'}> {t('game_interactive')} </MenuItem>
        </TextField>
        <TextField
          select
          fullWidth
          {...formikProps(formik, 'isActive')}
          label={t('status')}
          sx={{ mb: 2, width: { xs: '100%', sm: '100%' } }}
        >
          <MenuItem value={true}> {t('active')} </MenuItem>
          <MenuItem value={false}> {t('inactive')} </MenuItem>
        </TextField>
        {hasPointConfig && (
          <>
            <TextField
              label={t('point_correct')}
              {...formikProps(formik, 'pointCorrect')}
              sx={{
                mb: 2,
                width: { xs: '100%', sm: '50%' },
                pr: { xs: 0, sm: 2 }
              }}
            />
            <TextField
              label={t('point_incorrect')}
              {...formikProps(formik, 'pointIncorrect')}
              sx={{ mb: 2, width: { xs: '100%', sm: '50%' } }}
            />
            <TextField
              label={t('point_exchange_rate')}
              {...formikProps(formik, 'pointExchangeRate')}
              sx={{
                mb: 2,
                width: { xs: '100%', sm: '50%' },
                pr: { xs: 0, sm: 2 }
              }}
            />
            <TextField
              label={t('play_limit')}
              {...formikProps(formik, 'play_limit')}
              sx={{ mb: 2, width: { xs: '100%', sm: '50%' } }}
            />
          </>
        )}
        <ImageUploader
          formik={formik}
          label={t('game_logo')}
          name={`logo`}
          helperText={`< 1MB | PNG ${t('or')} JPEG`}
          sx={{ mb: 2 }}
        />
      </EditDialog>
      <EditDialog
        title={t('delete')}
        handleSave={() => doDeleteGame()}
        open={dialogMode === 'delete'}
        handleClose={() => setDialogMode("edit")}
        confirmText={t('confirm')}
      >
        <p>{t('delete_confirm_msg', { a: `${game?.name}` })}</p>
      </EditDialog>
    </LayoutContainer>
  );
}
