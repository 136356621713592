import {
    Box,
    Button,
    Card,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch,
    Typography
} from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import SubjectIcon from '@material-ui/icons/Subject';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import vars from 'src/config/vars';
import { useLocale } from 'src/provider/LocaleProvider';
import PropTypes from 'prop-types';




export function GameListCard(props) {
    const { game, setIsGameActiveInList } = props
    const { t, translate } = useLocale()
    const nav = useNavigate()
    return (
        <Grid
            key={game._id}
            item
            xs={12}
            sm={6}
            sx={{
                '& .MuiGrid-item': {
                    height: '120px'
                },
                '& img': {
                    height: '100%',
                    width: '100%'
                },
                '& .MuiTypography-root': {
                    overflow: 'hidden',
                    lineHeight: '25px',
                    maxHeight: '50px'
                }
            }}
        >
            <>
                <Card>
                    <Grid container>
                        {/* <NavLink to={`/admin/games/${game._id}`}> */}
                        <Grid container onClick={() => nav(`/admin/games/${game._id}`)}>
                            <Grid item sx={{ p: 2, height: '120px', width: '120px' }}>
                                <img src={game.logo || '/images/game.png'} alt="Game" />
                            </Grid>
                            <Grid item xs lg sx={{ p: 2 }}>
                                <Typography variant="h4">{game.name}</Typography>
                                <Typography color="textSecondary" variant="h5">
                                    {game.description}
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* </NavLink> */}
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ display: { xs: 'none', lg: 'block' } }}
                        />
                        <Divider
                            flexItem
                            sx={{
                                display: { xs: 'block', lg: 'none' },
                                width: '100%'
                            }}
                        />
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            sx={{ p: 1, display: 'flex', flexDirection: 'column' }}
                        >
                            <Box display="flex">
                                <DescriptionOutlinedIcon />
                                <Box pr={1} />
                                <Typography noWrap color="textSecondary" variant="h5">
                                    {t(`game_${game.gameType}`)}
                                </Typography>
                            </Box>
                            <Box display="flex">
                                <PeopleAltOutlinedIcon />
                                <Box pr={1} />
                                <Typography color="textSecondary" variant="h5">
                                    {_.size(game.filters) > 0
                                        ? _.map(game.filters, (f) => translate(f.name))
                                            .sort()
                                            .join(', ')
                                        : `${t('uncategorized')} (${t('all_grade_and_class')})`}
                                </Typography>
                            </Box>
                            <Box display="flex">
                                <SubjectIcon />
                                <Box pr={1} />
                                <Typography noWrap color="textSecondary" variant="h5">
                                    {_.size(game?.subjects) > 0
                                        ? _.map(game?.subjects, (f) => translate(f.name))
                                            .sort()
                                            .join(', ')
                                        : `${t('uncategorized')} (${t('all_subject')})`}
                                </Typography>
                            </Box>
                            <FormGroup >
                                <FormControlLabel control={<Switch onClick={() => setIsGameActiveInList(game._id)} checked={game.isActive} />}
                                    color="warning" label={game.isActive ? t('active') : t('inactive')} />
                            </FormGroup>
                            <Box flexGrow={1} />
                            {game.gameType === 'interactive' && (
                                <Button
                                    href={`${vars.extenalURL.gameInteractive}?gameId=${game._id}`}
                                    className="play_game_btn"
                                    target="_blank"
                                    size="small"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {t('play_game')}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Card>
            </>
        </Grid>
    );

}

const namePropType = PropTypes.shape({
    en: PropTypes.string.isRequired,
    zh: PropTypes.string.isRequired,
});

const filterPropType = PropTypes.shape({
    name: namePropType.isRequired,
    _id: PropTypes.string.isRequired,
    attribute: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
});

const subjectPropType = PropTypes.shape({
    name: namePropType.isRequired,
    isActive: PropTypes.bool.isRequired,
    _id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    organization: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired, // ISO 8601 format
    updated_at: PropTypes.string.isRequired, // ISO 8601 format
    __v: PropTypes.number,
    order: PropTypes.number.isRequired,
});

const mainObjectPropType = PropTypes.shape({
    filters: PropTypes.arrayOf(filterPropType).isRequired,
    subjects: PropTypes.arrayOf(subjectPropType).isRequired,
    isNumRandomQuestion: PropTypes.bool.isRequired,
    published: PropTypes.bool.isRequired,
    isActive: PropTypes.bool.isRequired,
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    gameType: PropTypes.string.isRequired,
    organization: PropTypes.string.isRequired,
    pointCorrect: PropTypes.number.isRequired,
    pointIncorrect: PropTypes.number.isRequired,
    pointExchangeRate: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired, // ISO 8601 format
    updated_at: PropTypes.string.isRequired, // ISO 8601 format
    __v: PropTypes.number,
    description: PropTypes.string, // optional
    logo: PropTypes.string, // optional
    id: PropTypes.string.isRequired,
});

GameListCard.propTypes = {
    game: mainObjectPropType,
    setIsGameActiveInList: PropTypes.func
};